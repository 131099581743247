import React, { useState, useEffect, useRef, useCallback } from "react";
import { useChatbotMessages } from "../../hooks/useChatbotMessage";
import MessageList from "./MessageList";
import MessageInputArea from "./MessageInputArea";
import APIKeyInputForm from "./APIKeyInputForm";
import { useUserAgreement } from "../../hooks/useUserAgreement";
import AgreementComponent from "./AgreementComponent";
import Header from "../Header";
import { v4 as uuidv4 } from "uuid";
import { authenticateUser } from "../../hooks/api";
import {
  prepareAndSendBotMessage,
  sendRewrittenMessage,
  saveAndUploadConversation,
  sendRating,
  requestSummarySurrogate,
  checkIfRepeatUser,
} from "../../hooks/chatbotService";
import { useUploadConversation } from "../../hooks/chatbotService";

// Include other necessary imports...

function ExperimentPage() {
  const isExperimentPage = true;

  const INITIAL_BOT_MESSAGE =
    "Hi there, I'm Mia, an AI mental health coach. I specialize in Cognitive Behavioral Therapy (CBT) strategies and reflective listening. Can you tell me your name and why you decided to reach out to me?";

  //get chatMessage from sessionStorage so that the messages are still there after refresh
  const [messages, addMessage, updateMessages] = useChatbotMessages(
    JSON.parse(sessionStorage.getItem("chatMessages")) || []
  );

  const [sessionId, setSessionId] = useState(
    sessionStorage.getItem("uuid") || false
  );
  const [hasAgreed, setHasAgreed] = useUserAgreement(
    sessionStorage.getItem("hasAgreed") === "true"
  );
  const [apiSuccess, setApiSuccess] = useState(
    sessionStorage.getItem("apiSuccess") === "true"
  );
  //check if user is repeat user
  const [hasCheckedRepeat, setHasCheckedRepeat] = useState(false);
  const [isAwaitingBotResponse, setIsAwaitingBotResponse] = useState(false);

  // select convversation to upload
  const [selectedFile, setSelectedFile] = useState(null);
  const { handleUploadChange, handleUploadConversation } =
    useUploadConversation(selectedFile, setSelectedFile, addMessage);

  // //styling the body of the chatbot page
  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add("chatbot-page");

    // Remove class from body when component unmounts
    return () => {
      document.body.classList.remove("chatbot-page");
    };
  }, []);

  //create a session id when the window is open
  useEffect(() => {
    if (!sessionStorage.getItem("uuid")) {
      const newSessionId = uuidv4();
      sessionStorage.setItem("uuid", newSessionId);
      setSessionId(newSessionId);
    }
  }, []); // Dependency on sessionId ensures this effect only runs when sessionId changes

  //check if user is a repeat user
  useEffect(() => {
    if (hasAgreed && !hasCheckedRepeat) {
      const initialMessageAdder = async () => {
        const hasAddedInitialMessage =
          sessionStorage.getItem("hasAddedInitialMessage") === "true";
        const userEmail = localStorage.getItem("userEmail");

        if (userEmail) {
          const isRepeatUser = await checkIfRepeatUser(userEmail);
          setHasCheckedRepeat(true); // Ensuring we don't re-check again
          sessionStorage.setItem(
            "isRepeatUser",
            isRepeatUser ? "true" : "false"
          );

          if (isRepeatUser) {
            //sessionStorage.setItem("isRepeatUser", "true");
            // Handle returning users
            if (!hasAddedInitialMessage || messages.length === 0) {
              // For returning users, welcome back message only if no initial message has been added, or no messages present
              addMessage(
                "bot",
                "Welcome back! How have you been since our last session? 😊"
              );
              sessionStorage.setItem("hasAddedInitialMessage", "true"); // Mark the initial message flag as true
            }
          } else {
            // Handle new users or users with cleared sessions
            if (!hasAddedInitialMessage) {
              // Adds the initial message only for new users or if the session was cleared and they are essentially "new" again
              addMessage("bot", INITIAL_BOT_MESSAGE);
              sessionStorage.setItem("hasAddedInitialMessage", "true"); // Mark the initial message flag as true
            }
          }
        }
      };

      initialMessageAdder();
    }
  }, [hasAgreed, hasCheckedRepeat, addMessage, messages.length]);

  //call the summary when window is closed
  useEffect(() => {
    // Function to call the summary backend
    const callSummaryBackend = async () => {
      // Your existing functionality to call the backend
      console.log("Calling Summary Backend...");
      await requestSummarySurrogate();
    };

    const handleBeforeUnload = (event) => {
      // Set a flag or timestamp to indicate the page is undergoing unload events
      // Example with timestamp
      sessionStorage.setItem("unloadTimestamp", Date.now().toString());
    };

    const handleUnload = async (event) => {
      // Check if a short duration has passed since `beforeunload`,
      // which might indicate it's more likely a tab close than a refresh
      const unloadTimestamp = Number(sessionStorage.getItem("unloadTimestamp"));

      // Add a condition to check if any messages were sent by the user (assumption: user messages have a specific type, e.g. 'user') we dont want to trigger the callSummaryBackend if user didnt send anything
      const userHasInput = messages.some((message) => message.type === "user");

      if (Date.now() - unloadTimestamp < 1000 && userHasInput && apiSuccess) {
        // 1 second threshold
        await callSummaryBackend();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      sessionStorage.removeItem("unloadTimestamp"); // Cleanup
    };
  }, [apiSuccess, messages]); // Empty array ensures this effect runs once on mount

  // Add state to track if a rewrite operation is in progress and which message it is for
  const [setRewriteState] = useState({
    isRewriting: false,
    messageId: null,
  });

  useEffect(() => {
    if (hasAgreed && messages.length === 0 && apiSuccess) {
      addMessage("bot", INITIAL_BOT_MESSAGE);
    }
  }, [hasAgreed, apiSuccess, messages.length, addMessage]);

  //reference the last message or a dummy `div` at the end of a list of messages. The purpose of this could be to manage scrolling behavior, particularly to automatically scroll to the bottom of the chat when a new message is added.
  const endOfMessagesRef = useRef(null);

  const handleApiKeySubmit = async (apiInput) => {
    try {
      const response = await authenticateUser(apiInput);
      console.log("this is the response", response);
      if (response === 1) {
        //if api key correct, store the apiInput, create new session id, and store the session id
        sessionStorage.setItem("api-key", apiInput);
        sessionStorage.setItem("apiSuccess", "true");
        // const newSessionId = uuidv4();
        // sessionStorage.setItem("uuid", newSessionId);
        // setSessionId(newSessionId);
        setApiSuccess(true);
        // Explicitly check and re-set session_id if needed
        if (!sessionStorage.getItem("uuid")) {
          const newSessionId = uuidv4();
          sessionStorage.setItem("uuid", newSessionId);
          setSessionId(newSessionId);
          console.log(
            "New session ID generated after API key submission: ",
            newSessionId
          );
          // Otherwise, ensure state is updated with current session_id
        } else {
          setSessionId(sessionStorage.getItem("uuid"));
          console.log(
            "Existing session ID reconfirmed after API key submission: ",
            sessionStorage.getItem("uuid")
          );
        }

        setApiSuccess(true);
      } else {
        alert("API key invalid");
        setApiSuccess(false);
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to validate the API key.",
        error
      );
      setApiSuccess(false);
    }
  };

  const handleAgree = () => {
    sessionStorage.setItem("hasAgreed", "true");
    setHasAgreed(true);
  };

  //Adding variables to the message
  const handleMessageSubmit = useCallback(
    async (messageText) => {
      if (!isAwaitingBotResponse) {
        // Only proceed if not awaiting bot response
        setIsAwaitingBotResponse(true); // Indicate we're waiting for a bot response
        //console.log("this is message text", messageText);
        addMessage("user", messageText); // updates the chat with the new message

        const humanTimestamp = new Date().toISOString(); //generate timestamp for human message
        const messageIndex = uuidv4();

        try {
          const botResponseData = await prepareAndSendBotMessage(
            messages,
            messageText,
            messageIndex,
            humanTimestamp
          );
          const botResponse = botResponseData.reply;
          //console.log('this is bot response', botResponse)

          const stateInfo = botResponseData.stateInfo;
          const nodeType = botResponseData.nodeType;

          // console.log("this is bot state", stateInfo);
          console.log("this is nodeType", nodeType);

          // Process and display the bot's response, adding the state info to the bot message
          addMessage("bot", botResponse, stateInfo, nodeType);
        } catch (error) {
          console.error("Error sending message to bot:", error);
        } finally {
          setIsAwaitingBotResponse(false);
        }
      }
    },
    [addMessage]
  );

  //retrieve rating from form and send it back to the server

  const handleRating = async (ratingType, messageId) => {
    let localUpdatedMessages = [...messages];
    localUpdatedMessages = localUpdatedMessages.map((message) =>
      message.id === messageId
        ? {
            ...message,
            ratingUp:
              ratingType === "up" ? !message.ratingUp : message.ratingUp,
            ratingDown:
              ratingType === "down" ? !message.ratingDown : message.ratingDown,
          }
        : message
    );

    sessionStorage.setItem(
      "chatMessages",
      JSON.stringify(localUpdatedMessages)
    );
    updateMessages(localUpdatedMessages); // assuming this updates the state

    //  handling the API call with updated local variable
    const ratedMessage = localUpdatedMessages.find(
      (message) => message.id === messageId
    );
    if (ratedMessage) {
      console.log(ratedMessage); // Make sure you're logging the right variable
      const sessionId = sessionStorage.getItem("uuid") || ""; // Ensure you get the session ID as needed
      await sendRating(ratedMessage, ratingType, messageId, sessionId);
    }
  };

  const submitRewrittenMessageToChat = async (messageId, rewrittenText) => {
    let originalText = "";

    //updating the main chat messages state with the rewritten message text.
    // const updatedMessages = messages.map((message) => {
    //   if (message.id === messageId) {
    //     const originalText = message.originalText || message.text; // Keep original or current text as original
    //     return { ...message, text: rewrittenText, originalText: originalText };
    //   }
    //   return message;
    // });

    console.log("print sth");
    //updateMessages(updatedMessages); // Use the correct method to update messages state

    try {
      await sendRewrittenMessage(messageId, originalText, rewrittenText); // Adjust accordingly
      setRewriteState({ isRewriting: false, messageId: null });
    } catch (error) {
      console.error("Error sending rewritten message:", error);
    }
  };

  //user can scroll down the messages
  useEffect(() => {
    if (isScrolledToBottomBeforeUpdate) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const isScrolledToBottomBeforeUpdate = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      document.querySelector(".msger-chat");
    return scrollHeight - scrollTop === clientHeight;
  };

  return (
    <>
      <div className="chatbot-page">
        <Header />
        <div className="body_chat">
          <section className="msger">
            <header className="msger-header">
              <div className="msger-header-title">Mia</div>
            </header>

            <main className="msger-chat">
              {/* user agreement */}
              {!hasAgreed && (
                //  <AgreementComponent onAgree={() => setHasAgreed(true)} />
                <AgreementComponent onAgree={handleAgree} />
              )}

              {/* input apikey */}
              {hasAgreed && !apiSuccess && (
                <>
                  {!apiSuccess && (
                    <APIKeyInputForm onSubmit={handleApiKeySubmit} />
                  )}
                </>
              )}

              {/* user can enter message if.. */}
              {hasAgreed && sessionId && apiSuccess && (
                <>
                  {/* submit rating */}

                  <MessageList
                    messages={messages}
                    onRating={handleRating}
                    endOfMessagesRef={endOfMessagesRef}
                    onRewriteInitiate={submitRewrittenMessageToChat}
                    isExperiment={true}
                  />
                </>
              )}
            </main>

            {/* submit messsage */}

            {/* <MessageInputArea onMessageSubmit={handleMessageSubmit} /> */}
            <MessageInputArea
              onMessageSubmit={handleMessageSubmit}
              onConversationEnd={requestSummarySurrogate} // Pass the function as a prop
              isAwaitingBotResponse={isAwaitingBotResponse}
            />

            {isExperimentPage && (
              <div style={{ padding: "10px" }}>
                <button
                  onClick={() => saveAndUploadConversation(messages, sessionId)}
                >
                  Download Conversation
                </button>
              </div>
            )}

            {/* <input
              type="file"
              id="upload-convo"
              style={{ display: "none", padding: "10px" }}
              onChange={handleUploadChange}
              accept=".csv"
            />
            <label htmlFor="upload-convo" className="upload-button">
              Choose File
            </label>
            <div style={{ padding: "10px" }}>
              <button
                onClick={handleUploadConversation}
                disabled={!selectedFile}
              >
                Upload Conversation
              </button>
            </div> */}

            {/* add the record button if needed */}
          </section>
        </div>
      </div>
    </>
  );
}

export default ExperimentPage;
