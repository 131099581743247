import axios from "axios";
import CONFIG from "../config";
import { v4 as uuidv4 } from "uuid";
import AES from "crypto-js/aes";
import { useState, useEffect } from "react";

// Function to directly send message to the server
export async function sendBotMessageBaseline(data) {
  const response = await axios.post(`${CONFIG.BASE_URL}/reply_baseline`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  console.log("this is the baseline response", response);

  console.log("Bot reply:", response.data["reply"]);
  // console.log("State information:", response.data['stateInfo']);
  //return response.data;

  return response.data;
}

// Function to prepare data and call sendBotMessage, encrypt the messages field
export async function prepareAndSendBotMessageBaseline(
  messages,
  humanMessage,
  messageIndex,
  humanTimestamp
) {
  const userEmail = localStorage.getItem("userEmail") || "";
  const sessionId = sessionStorage.getItem("uuid") || uuidv4(); // Generate a new sessionId if none exists

  const aiReplies = messages
    .filter((msg) => msg.type === "bot")
    .map((msg) => msg.text);
  let humanReplies = messages
    .filter((msg) => msg.type === "user")
    .map((msg) => msg.text);
  humanReplies.push(humanMessage); // Append the latest user message

  //encrypt human turns
  const encryptedHumanTurns = AES.encrypt(
    JSON.stringify(humanReplies),
    process.env.REACT_APP_SECRET_KEY
  ).toString();

  //encrypt ai turns
  const encryptedAITurns = AES.encrypt(
    JSON.stringify(aiReplies),
    process.env.REACT_APP_SECRET_KEY
  ).toString();

  const postData = new FormData();
  //postData.append('ai_turns', JSON.stringify(aiReplies));

  //encrypt human turns
  //postData.append('human_turns', JSON.stringify(humanReplies));
  postData.append("human_turns", encryptedHumanTurns);
  postData.append("ai_turns", encryptedAITurns);
  postData.append("human_timestamp", JSON.stringify(humanTimestamp));
  postData.append("api_key_value", JSON.stringify("aurora"));
  postData.append("session_id_value", JSON.stringify(sessionId));
  //postData.append('session_id_value', encryptedSessionId);

  postData.append("user_email", JSON.stringify(userEmail));
  postData.append("user_correction", JSON.stringify(null));
  postData.append("message_id_db", JSON.stringify(messageIndex));
  postData.append("bot_message_id", JSON.stringify(messageIndex)); // Assuming messageIndex is the botMessageId for simplicity.

  return sendBotMessageBaseline(postData);
}
