import "normalize.css/normalize.css";
import "./css/style_chatbot.scss";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Intro from "./components/Intro";
import ResearchPage from "./components/ResearchPage";
import LoginPage from "./components/LoginPage";
// import ContactPage from './components/ContactPage';
import HomePageHeader from "./components/HomePageHeader";
import ProfilePage from "./components/ProfilePage";
import SignUpPage from "./components/SignUpPage";
import ExperimentPage from "./components/Chatbot/ChatbotIndex_experiment";
import Chatbot from "./components/Chatbot/ChatbotIndex";
import MobileDownload from "./components/MobileDownload";
import EmotionRegulationToolkit from "./components/BehavioralActivation";
//import { isIOS } from "react-device-detect";
import { isMobile } from "react-device-detect";
import Experiment2 from "./components/Chatbot/ChatbotIndex_experiment2";

const handleTouch = (e: React.TouchEvent) => {
  e.preventDefault();
};
const App = () => {
  if (isMobile) {
    return <MobileDownload />; // Show only the MobileDownload component for iOS users
  }

  return (
    <Router>
      <div onTouchStart={handleTouch} onTouchMove={handleTouch}></div>
      <div>
        <Routes>
          <Route path="/" element={<HomePageHeader />} />
          <Route path="/intro" element={<Intro />} />
          <Route path="/research" element={<ResearchPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/chatbot" element={<Chatbot />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/experiment" element={<ExperimentPage />} />
          <Route path="/experiment2" element={<Experiment2 />} />
          <Route path="/emotionReg" element={<EmotionRegulationToolkit />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
